import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 多语言
import VueI18n from 'vue-i18n'
// 导入样式重置文件
import './assets/css/reset.css'
// 导入全局样式
import './assets/css/global.css'
// 图标
import './assets/font/iconfont.css'

// 导入并配置axios
import axios from 'axios'

// 导入vant框架
import Vant from 'vant'
import 'vant/lib/index.css'
// import Language from './lang/lang.json'
// 修改代理，四个websocket地址[index,trade/index,trade/contact,trade/contact_list]
axios.defaults.baseURL = 'https://roundspheres.vip/'
//axios.defaults.baseURL = '/api'
// 请求拦截
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.localStorage.getItem('token')
    return config
})
// 响应拦截
axios.interceptors.response.use(response => {
    if (response.data.code === 401) {
        window.localStorage.removeItem('token')
        router.push('/login')
        return false
    } else {
        return response
    }
})
if (localStorage.getItem('lang') == 'false' || localStorage.getItem('lang') == null || localStorage.getItem('lang') == "") {
    localStorage.setItem('lang', 'en');
}
// 将axios挂载为全局方法$http
Vue.prototype.$http = axios
// Vue.prototype.wsurl = 'ws://172.104.185.88'
Vue.prototype.wsurl = 'wss://roundspheres.vip'
// 全局函数
Vue.prototype.getlang = (msg, lang) => {
    if (!lang || lang == 'false' || lang == null || lang == '') {
        lang = 'en'
    }
    let data = require(`./lang/${lang}.json`);
    console.log("\""+msg+"\":\""+msg+"\",");
    return data[msg] ?? msg;
};

// 全局时间过滤器
Vue.filter('dateformat', function (time) {
    const dt = new Date(parseInt(time) * 1000)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
// 多语言
Vue.use(VueI18n)
const zh = require('./lang/zh.json')
const en = require('./lang/en.json')
const jp = require('./lang/jp.json')
const spa = require('./lang/spa.json')
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en',
    messages: {
        zh: {
            ...zh
        }, // 中文语言包
        en: {
            ...en
        }, // 英文语言包
        jp: {

            //日文语言包
            ...jp
        }, // 英文语言包
        spa: {

            //西班牙语言包
            ...spa
        }
    }
})
// Vue.prototype.$Local = Locals
// vant框架
Vue.use(Vant)
Vue.config.productionTip = false
new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')
